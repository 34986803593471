import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';  
import VueScrollTo from 'vue-scrollto';  
 
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease"
})

Vue.config.productionTip = false

new Vue({
  vuetify,   
  render: h => h(App)
}).$mount('#app')
