<template>
  <div>
    <div class="bulkestimate-event">  
      <div class="floating-btn_apply" v-if="showFloatingBtn" v-scroll-to="'#scrollTarget'">
        <div class="text">벌크 견적받고 <span class="font-bold">할인 혜택</span>을 받아보세요</div>
        <div class="main-text font-bold">벌크 컨설팅 신청하기 <img src="../assets/images/common/icon_arrow.png" alt=">" class="icon-arrow"></div>
      </div> 
      <div class="event-top-banner">
        <div class="wrapper event-top-banner__wrapper">
          <span class="banner__text">이미 많은 고객사에서</span>
          <span class="banner__text banner__text-bold"><span class="text-purple font-black">벌크<small>(대용량 주문)</small>의 장점</span>을 경험 하셨습니다.</span>   
        </div>
      </div> 
      <div class="event-carousel">   
        <div class="wrapper event-carousel__wrapper">
          <carousel  
            :items="1"
            :center="true" 
            :loop="true" 
            :autoplay="true"  
            :nav="false" 
            :dots="false"
            :margin="20"
            :responsive="{
              0:{   
                stagePadding: 0
              },
              750:{   
                stagePadding: 50
              },
              1200: { 
                stagePadding: 120 
              }
            }" 
          >   
            <div v-for="client in clients" :key="client.index">
              <div class="count"><span>{{client.number}}</span> / {{clients.length}}</div>    
              <img :src="client.imgUrl" class="item-web">
              <img :src="client.imgUrl_m" class="item-mobile"> 
            </div>
          </carousel> 
        </div>
      </div> 
      <div class="bulk-purchase-info"> 
        <div class="wrapper bulk-purchase-info__wrapper"> 
          <div class="bulk-purchase-info__title">
            <div class="logo-group">
              <img class="logo-merck" src="../assets/images/common/logo_merck_yellow.png" alt="merck"> 
              <img class="logo-sigma" src="../assets/images/common/logo_sigma.png" alt="Sigma-Aldrich">  
            </div>
            <!-- <div class="vdn">Merck/SigmaAldrich</div> -->
            <span>벌크용량 구매의 장점</span>을 알고 계신가요?
          </div>  
          <div class="purchase-info">
            <div class="bulk-purchase-info__subtitle">벌크 구매 가능 조건</div>
            <div class="purchase-info__content"><span class="vdn">Prepack</span> 용량의 <span class="vdn">20</span>배 혹은 총 구매 <span class="vdn">500</span>만원이상</div>
          </div>
          <div class="purchase-advantage">
            <div class="bulk-purchase-info__subtitle">벌크 구매 가능 장점</div>
            <div class="purchase-advantage__content">
              <img src="../assets/images/contents/img_advantage.png" alt="벌크 구매 가능 장점" class="content_web"> 
              <img src="../assets/images/contents/img_advantage_m.png" alt="벌크 구매 가능 장점" class="content_m"> 
            </div> 
          </div> 
        </div>
      </div>  
      <div class="estimate-content" ref="scrollContent" data-app id="scrollTarget" >
        <div class="wrapper estimate-content__wrapper"> 
          <div class="estimate-content__title">
            <img src="../assets/images/form/icon_discount.png" alt="아이콘" class="icon-discount">
            <div class="text">간단히 <span class="text-blue">벌크 견적</span>을 받고<br/><span class="text-blue">할인 혜택</span>을 받아보세요!</div> 
          </div>
          <v-form ref="form" v-model="valid" class="estimate-form" lazy-validation>
            <label class="estimate-form__label">업체명</label>
            <v-text-field
              v-model="form.company"
              :rules="companyRules"
              color="#2DBECD" 
              class="estimate-form__input"
              required
            ></v-text-field>
            <label class="estimate-form__label">이름</label>
            <v-text-field
              v-model="form.name"
              :rules="nameRules"
              color="#2DBECD" 
              class="estimate-form__input"
              required
            ></v-text-field>
            <label class="estimate-form__label">휴대폰 번호</label>
            <v-text-field
              v-model="form.phone"
              :rules="phoneRules"
              color="#2DBECD" 
              class="estimate-form__input"
              hint="하이픈(-) 제외"
              required
            ></v-text-field>
            <label class="estimate-form__label">이메일 주소</label>
            <v-text-field
              v-model="form.email"
              type="email"
              :rules="emailRules"
              color="#2DBECD" 
              class="estimate-form__input"
              required
            ></v-text-field>
            <label class="estimate-form__label">벌크 견적 받으실 제품번호 및 브랜드</label>
            <v-text-field
              v-model="form.product"
              :rules="productRules"
              color="#2DBECD" 
              class="estimate-form__input"
              required
            ></v-text-field>
            <label class="estimate-form__label">원하시는 용량 및 단위 (예시:<span class="vdn">50kg</span>)</label>
            <v-text-field
              v-model="form.volume"
              :rules="volumeRules"
              color="#2DBECD" 
              class="estimate-form__input"
              required
            ></v-text-field>
            <label class="estimate-form__label">희망하시는 포장 단위 (예시: <span class="vdn">10kg x 5EA</span>)</label>
            <v-text-field
              v-model="form.unit"
              :rules="unitRules"
              color="#2DBECD" 
              class="estimate-form__input"
              required
            ></v-text-field>
            <label class="estimate-form__label">위와 동일한 제품의 벌크 구매 이력이 있습니까?</label>
            <v-radio-group
              v-model="form.purchase"
              :rules="purchaseRules"
              class="estimate-form__input estimate-form__radio"
              row
              hide-details
              required
            >
              <v-radio label="예" value="예" :ripple="false" color="#2DBECD"></v-radio>
              <v-radio label="아니오" value="아니오" :ripple="false" color="#2DBECD"></v-radio>
            </v-radio-group> 
            <label class="estimate-form__label">해당 제품은 어떤 용도로 사용하실 예정이십니까?</label>
            <v-radio-group
              v-model="form.purpose"
              :rules="purposeRules"
              class="estimate-form__input estimate-form__radio"
              hide-details
              row
              required
            >
              <v-radio label="생산/제조" value="생산/제조" :ripple="false" color="#2DBECD"></v-radio>
              <v-radio label="연구/시험" value="연구/시험" :ripple="false" color="#2DBECD"></v-radio>
              <v-radio label="기타" value="기타" :ripple="false" color="#2DBECD"></v-radio>
            </v-radio-group>  
            <v-btn
              :disabled="!valid"
              color="#2DBECD" 
              @click="send(form)"
              depressed
              class="estimate-form__button"
            >벌크 컨설팅 신청</v-btn>
          </v-form>  
        </div>
      </div> 
      <div class="estimate-info">
        <div class="wrapper estimate-info__wrapper">
          <div class="estimate-info__title text-blue">꼭 미리 확인해 주세요</div>
          <div class="estimate-info__list">
            <div class="list">
              <div class="num vdn">1.</div>
              <div class="text">벌크 견적은 신청 시기 및 업체 이력에 따라 달라질 수 있습니다.</div>
            </div> 
            <div class="list">
              <div class="num vdn">2.</div>
              <div class="text"><span class="vdn">Pre-Pack</span>전용 제품은 벌크 견적 진행이 불가합니다.</div>
            </div>
            <div class="list">
              <div class="num vdn">3.</div>
              <div class="text">벌크 제품은 발주 후 납기까지 소요되는 시간이 상이합니다.<br/>견적 제안 시에 <span class="vdn">Lead Time</span>을 함께 공유 드리겠습니다.</div>
            </div>
            <div class="list">
              <div class="num vdn">4.</div>
              <div class="text">추가적인 문의는 <a href="mailto:rnainsidesales@merckgroup.com" class="text__mail vdn">rnainsidesales@merckgroup.com</a>으로 연락 부탁 드립니다.</div>
            </div>
          </div>  
        </div>
      </div>

      <v-overlay :value="isLoading" :opacity="0.2">
        <v-btn icon>
          <v-progress-circular
            :size="50"
            color="#2DBECD"
            indeterminate
          ></v-progress-circular>
        </v-btn>
      </v-overlay>
 
      <v-dialog v-model="dialog" class="dialog">
        <div class="dialog-success">
          <div class="dialog-success__title">신청해 주셔서 감사합니다.<br/>일주일 내로 메일을 통해 견적을 전달 드리겠습니다.</div>
          <div class="dialog-success__text">문의 : <span class="vdn">rnainsidesales@merckgroup.com</span></div>
          <v-btn 
            color="#2DBECD" 
            @click="dialog = false"
            class="dialog-success__btn" 
            depressed
          >확인</v-btn> 
        </div>
      </v-dialog> 
    </div>
  </div>
</template>

<script>    
import carousel from 'vue-owl-carousel'
import axios from "axios"

export default { 
  components: { carousel },
  data: () => ({
    dialog: false,
    isLoading: false,
    valid: true,
    showFloatingBtn: true,
    scrollContent: null,
    form: {
      company: '',
      name: '',
      phone: '',
      email: '',
      product: '',
      volume: '',
      unit: '',
      purchase: '',
      purpose: '',
    },
    companyRules: [v => !!v || "회사명을 입력해주세요."],
    nameRules: [v => !!v || "이름을 입력해주세요."],
    phoneRules: [
      v => !!v || "휴대폰 번호를 입력해주세요.",
      v => /^[0-9]*$/.test(v) || "형식에 맞지않는 번호 입니다.",
      v => (v && v.length <= 11) || "형식에 맞지않는 번호 입니다."
    ],
    emailRules: [
      v => !!v || "이메일을 입력해주세요.",
      v => /.+@.+\..+/.test(v) || "이메일 주소 형식이 아닙니다."
    ],
    productRules : [v => !!v || "벌크 견적 받으실 제품번호 및 브랜드를 입력해주세요."],
    volumeRules: [v => !!v || "원하시는 용량 및 단위를 입력해주세요."],
    unitRules: [v => !!v || "희망하시는 포장 단위를 입력해주세요."],
    purchaseRules: [v => !!v],
    purposeRules: [v => !!v],  
    count: 1,
    clients:[
      {
        number: 1,
        name: '고객사 A',
        imgUrl: require("../assets/images/carousel/card_a_web.png"),
        imgUrl_m: require("../assets/images/carousel/card_a_m.png"),
      },
      {
        number: 2,
        name: '고객사 B',
        imgUrl: require("../assets/images/carousel/card_b_web.png"),
        imgUrl_m: require("../assets/images/carousel/card_b_m.png"),
      },
      {
        number: 3,
        name: '고객사 C',
        imgUrl: require("../assets/images/carousel/card_c_web.png"),
        imgUrl_m: require("../assets/images/carousel/card_c_m.png"),
      }
    ]
  }),
  mounted() {   
    window.addEventListener('scroll', this.handleScroll) 
  },
  beforeDestory() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {  
    handleScroll() {  
      if(this.$refs.scrollContent) {
        const formAreaTop = this.$refs.scrollContent.offsetTop;
        const windowBottom = window.innerHeight + window.pageYOffset; 
        this.showFloatingBtn = formAreaTop < windowBottom ? false : true;   
      } 
    }, 
    send(form) { 
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        
        const data = {
          company: form.company, 
          name: form.name,
          phone: form.phone,  
          email: form.email,
          product: form.product,
          volume: form.volume,
          unit: form.unit,
          purchase: form.purchase,
          purpose: form.purpose,
        };
        
        axios
          .post("/sendMail", data)
          .then(() => {
            this.isLoading = false;
            this.dialog = true;
            this.$refs.form.reset();
          })
          .catch(() => {
            alert('전송에 실패했습니다. 다시 시도해주세요.')
          })
          .finally(() => {
            this.isLoading = false;
          });
      }
    }
  }
};
</script>

<style lang="scss">
@import "../assets/variables.scss";  
.bulkestimate-event {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; 
  .wrapper {
    padding-left: 20px;
    padding-right: 20px;
  } 
  .event-top-banner { 
    background-color: $blue;
    background-image: url('../assets/images/top/bg_product.png');
    background-position: right bottom; 
    text-align: center; 
    &__wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: {
        top: 80px;
        bottom: 80px;
      }
    }
    .banner__text {
      font-size: 36px;
      font-weight: normal;
      color: $white;
      letter-spacing: -0.05em;
      line-height: 1.3;
      &-bold {
        position: relative;
        font-weight: 700;  
        small {
          font-weight: 700;   
        }
        &::before, 
        &::after {
          content: ''; 
          position: absolute;
          top: 6px;
          width: 22px;
          height: 16px; 
        }
        &::before {
          left: -40px;
          background: url('../assets/images/top/icon_quote_l.png') no-repeat;
          background-size: contain;
        }
        &::after { 
          right: -40px; 
          background: url('../assets/images/top/icon_quote_r.png') no-repeat;
          background-size: contain;
        }
      }
    }
  } 
  .event-carousel {
    position: relative;
    padding-top: 32px;
    padding-bottom: 32px; 
    &__wrapper {
      padding-left: 0;
      padding-right: 0;
    }
    .owl-item {
      position: relative;
      transition: opacity 0.4s;
      &:not(.center) {
        opacity: 0.3; 
        .count {
          display: none;
        }
      } 
      .count { 
        position: absolute;
        right: 16px;
        bottom: 16px;  
        padding: 9px 14px;
        background: rgba(0,0,0,0.25);
        border-radius: 100px; 
        font-family: "Verdana Pro" !important;  
        font-size: 20px;
        letter-spacing: -0.05em;
        color: #cecece;
        line-height: 1;
        span { 
          font-family: "Verdana Pro" !important;  
          color: $white;
          font-weight: 500;
          line-height: 1;
        }
      }
      .item-web {
        display: block;
      }
      .item-mobile {
        display: none;
      }
    } 
  }
  .bulk-purchase-info {
    &__wrapper {
      padding-top: 40px;
      padding-bottom: 40px; 
    }
    background: $purple;
    text-align: center;
    &__title {
      margin-bottom: 28px;
      color: $white;
      font-size: 30px;
      font-weight: 700;
      line-height: 1.3;
      letter-spacing: -0.05em;
      .logo-group {
        margin-bottom: 25px;
        img { 
          vertical-align: middle;
        }
        .logo-merck {
          height: 20px;
          margin-right: 32px;
        }
        .logo-sigma {
          height: 34px;
        }
      }
      .vdn {
        font-weight: 500;
      }
      span {
        color: $blue;
      }
    }
    &__subtitle {
      position: relative;
      display: inline-block;
      padding: 0.409em 1.182em;
      font-size: 22px;
      letter-spacing: -0.05em;
      font-weight: 700;
      line-height: 1;
      color: $purple;
      z-index: 1;
      &::after {
        content: '';
        position: absolute;
        left: calc(50% - 9px);
        top: 50%;
        width: 100%;
        height: 100%;
        background: $yellow;
        transform: skew(-20deg) translate(-50%, -50%);
        z-index: -1;
      } 
    }
    .purchase-info { 
      margin-bottom: 36px;
      &__content {
        margin-top: 16px;
        color: $white;
        font-size: 18px;
      }
    }
    .purchase-advantage {
      &__content {
        max-width: 760px;
        margin: 0 auto;
        margin-top: -20px;  
        padding: 40px;
        border-radius: 12px;
        background: rgba(255, 255, 255, 0.07); 
        .content_web {
          display: block;
        }
        .content_m {
          display: none;
        }
      }
    }
  } 
  .estimate-content {
    &__wrapper {
      padding-top: 32px;
      margin: 0 auto;
      max-width: 580px;
    }
    &__title {
      margin-bottom: 40px;
      text-align: center;
      .icon-discount {
        display: block;
        margin: 0 auto;
        width: 48px;
        height: 48px;
        margin-bottom: 14px;
      }
      .text {
        font-size: 30px;
        font-weight: 700;
        letter-spacing: -0.05em;
        line-height: 1.3;
        color: $purple;
      }
    }
    .estimate-form {
      margin-bottom: 48px;
      &__label {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -0.05em;
        line-height: 1;
        color: $black;
      }
      &__input {
        margin-top: 0;
        padding-top: 10px;
        margin-bottom: 20px;
        font-size: 18px;
        input { 
          max-height: 40px;
        }
      }
      &__radio {
        padding-top: 20px;
        margin-bottom: 50px;
        .v-radio {
          margin-right: 48px;
          &> .v-label {
            margin-left: 8px;
            color: $black; 
            letter-spacing: -0.05em;
          }
        }
      }
      &__button { 
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 260px;
        height: 52px !important;
        border-radius: 0.357em;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: -0.04em;
        color: $white; 
      } 
    } 
  } 
  .estimate-info { 
    &__wrapper {  
      padding: 24px 0;
      margin: 0 auto;
      max-width: 540px;
      border-top: 1px solid #efefef;
      border-bottom: 1px solid #efefef;
    }
    &__title {
      margin-bottom: 16px;
      line-height: 1;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.05em; 
    }
    &__list { 
      .list {
        display: flex;
        font-size: 14px;
        line-height: 1.4;
        word-break: keep-all;
        font-weight: normal;
        letter-spacing: -0.05em;
        &:not(:last-child) {
          margin-bottom: 4px;
        }
        .num {
          margin-right: 3px;
        } 
        .text__mail {
          font-weight: 500;
          color: $black;
        }
      } 
    } 
  }
  .floating-btn_apply {
    display: none;
    position: fixed;
    left: 50%;
    bottom: 40px;
    width: calc(100% - 40px);
    padding: 24px;
    border: 3px solid $purple;
    border-radius: 15px;
    text-align: center;
    color: $purple;
    background: $white;
    letter-spacing: -0.05em;
    box-shadow: 0px 10px 25.11px 1.89px rgba(59, 59, 59, 0.45);
    transform: translateX(-50%) translateY(0);
    z-index: 10;
    cursor: pointer;
    animation: bounce 2s infinite;
    .text {
      font-size: 23px;
      line-height: 1;
      margin-bottom: 0.609em;
    }
    .main-text {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 32px;
      line-height: 1;
      .icon-arrow {
        display: block;
        margin-left: 0.438em;
        width: 0.375em;
      }
    }
  }
  .dialog-success {
    background: $white;
    text-align: center;
    padding: 42px 20px 28px;
    border-radius: 12px;
    &__title { 
      margin-bottom: 20px;
      font-size: 18px;
      font-weight: 700;
      letter-spacing: -0.05em;
      line-height: 1.3;
      word-break: keep-all;
    }
    &__text {
      margin-bottom: 40px;
      font-size: 14px;
      letter-spacing: -0.05em;
      line-height: 1;
    }
    &__btn {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 200px;
      height: 52px !important;
      border-radius: 0.357em;
      font-size: 18px !important;
      font-weight: 700;
      letter-spacing: -0.05em;
      color: $white !important; 
    }
  }
  .v-dialog {
    max-width: 500px; 
  } 
  @keyframes bounce {
    0% {
      bottom: 40px;  
    } 
    50% {
      bottom: 30px; 
    }
    100% {
      bottom: 40px; 
    }
  } 

  @media (max-width: 750px) {
    .event-top-banner { 
      background-size: auto 80px;
      &__wrapper { 
        padding: {
          top: 70px;
          bottom: 70px;
        }
      }
      .banner__text {
        font-size: 32px;    
        &-bold {  
          &::before, 
          &::after { 
            top: 6px;
            width: 20px;
            height: 14px; 
          }
          &::before {
            left: -30px; 
          }
          &::after { 
            right: -30px;  
          }
        }
      }
    } 
    .event-carousel {
      &__wrapper {
        padding-left: 16px;
        padding-right: 16px;
      }
      .owl-item {
        .count {
          display: none;
        }
        .item-web {
          display: none;
        }
        .item-mobile {
          display: block;
        }
      }
    }
    .bulk-purchase-info {
      &__wrapper {
        padding-left: 40px;
        padding-right: 40px;
      } 
      &__title {
        margin-bottom: 0.833em; 
        font-size: 36px;  
        .logo-group {
          margin-bottom: 32px; 
          .logo-merck {
            height: 26px;
            margin-right: 36px;
          }
          .logo-sigma {
            height: 44px;
          }
        }
      }
      &__subtitle { 
        font-size: 28px; 
      }
      .purchase-info { 
        margin-bottom: 40px;
        &__content {
          margin-top: 1.111em; 
          font-size: 24px;
        }
      }
      .purchase-advantage {
        &__content { 
          margin-top: -22px;  
          padding: 44px 36px 32px;  
          .content_web {
            display: none;
          }
          .content_m {
            display: block;
          }
        }
      }
    } 
    .estimate-content {
      &__wrapper {  
        max-width: 660px;
      }
      &__title { 
        .icon-discount { 
          margin-bottom: 12px;
        }
        .text {
          font-size: 36px; 
        }
      }
      .estimate-form {
        margin-bottom: 60px;
        &__label {
          font-size: 24px; 
        }
        &__input { 
          padding-top: 0.500em;
          margin-bottom: 0.833em;
          font-size: 28px;
          input { 
            max-height: 50px;
          }
        }
        &__radio {
          padding-top: 32px; 
          margin-bottom: 52px;
          .v-radio {
            margin-right: 58px;
            &> .v-label {
              margin-left: 0.538em;
              font-size: 24px;
            } 
            .v-input--selection-controls__input {
              width: 36px;
              height: 36px;
            }
            .v-icon.v-icon {
              font-size: 36px;
            }
          }
        }
        &__button {   
          max-width: 620px;
          height: 92px !important; 
          font-size: 30px;  
        }  
      }   
    } 
    .estimate-info { 
      &__wrapper {  
        padding: 32px 0; 
        max-width: 620px; 
      }
      &__title {
        margin-bottom: 1.077em; 
        font-size: 26px; 
      }
      &__list { 
        .list { 
          font-size: 22px; 
          &:not(:last-child) {
            margin-bottom: 10px;
          }  
        } 
      } 
    } 
    .dialog-success {  
      padding: 48px 20px 38px; 
      &__title { 
        max-width: 330px;
        margin: 0 auto;
        margin-bottom: 0.857em;
        font-size: 28px; 
      }
      &__text {
        margin-bottom: 1.750em;
        font-size: 24px; 
      }
      &__btn { 
        max-width: 400px;
        height: 92px !important; 
        font-size: 30px !important; 
      }
    }
    .v-dialog {
      max-width: 620px;
    } 
  
    .floating-btn_apply {
      display: block;  
    }   
  }
  @media (max-width: 700px) {
    .event-top-banner {
      background-size: auto 11vw;
      &__wrapper {
        padding: {
          top: 10vw;
          bottom: 10vw;
        }
      }
      .banner__text {
        font-size: 4.5vw;  
        &-bold {
          &::before, 
          &::after { 
            top: 0.8vw;
            width: 2.6vw;
            height: 1.8vw; 
          }
          &::before {
            left: -4vw; 
          }
          &::after { 
            right: -4vw;  
          } 
        }
      }
    }
    .event-carousel {
      padding-top: 20px;
      padding-bottom: 20px;  
      .owl-item {
        &:not(.center) {
          opacity: 1;  
        }  
      }
    }
    .bulk-purchase-info {
      &__wrapper {
        padding: 6vw;
      }
      &__title {
        margin-bottom: 0.833em; 
        font-size: 5.2vw;   
        .logo-group {
          margin-bottom: 4.5vw; 
          .logo-merck {
            height: 3.7vw;
            margin-right: 5.14vw;
          }
          .logo-sigma {
            height: 6.28vw;
          }
        }
      } 
      &__subtitle { 
        font-size: 4vw; 
        &::after {
          left: calc(50% - 1.2vw); 
        }
      }
      .purchase-info {
        margin-bottom: 5.7vw;
        &__content { 
          font-size: 3.6vw;
        } 
      }
      .purchase-advantage {
        &__content {
          margin-top: -3vw;  
          padding: 6vw 5vw 4.5vw;  
        }
      }
    } 
    .estimate-content {
      &__wrapper {
        padding-top: 4.5vw; 
      } 
      &__title {
        margin-bottom: 5.7vw;
        .icon-discount {
          width: 7vw;
          height: 7vw;
          margin-bottom: 2vw;
        }
        .text {
          font-size: 5.2vw; 
        }
      }
      .estimate-form {
        margin-bottom: 8.5vw;
        &__label {
          font-size: 4vw;
        }
        &__input {
          font-size: 4.2vw;
        }
        &__radio {
          padding-top: 4.5vw;
          margin-bottom: 7.4vw;
          .v-radio {
            margin-right: 4vw;
            &> .v-label { 
              margin-left: 0.2em;
              font-size: 4vw;
            }  
            .v-input--selection-controls__input {
              width: 5vw;
              height: 5vw;
            }
            .v-icon.v-icon {
              font-size: 5vw;
            }
          }
        }
        &__button { 
          height: 13vw !important; 
          font-size: 4.5vw;  
        }
      } 
    }
    .estimate-info {
      padding-left: 20px;
      padding-right: 20px; 
      &__wrapper { 
        padding: 4.5vw 0;
      }
      &__title { 
        font-size: 4vw; 
      }
      &__list { 
        .list { 
          font-size: 3.2vw; 
          &:not(:last-child) {
            margin-bottom: 1.2vw;
          }  
        } 
      } 
    }
    .dialog-success {
      padding: 6.8vw 2.8vw 5.4vw; 
      &__title {
        max-width: 47vw;
        font-size: 4vw;  
      }
      &__text {
        font-size: 3.5vw;
      }
      &__btn {
        max-width: 57vw;
        height: 13vw !important; 
        font-size: 4.3vw !important;  
      } 
    }
    .floating-btn_apply {
      padding: 3.4vw;
      .text {
        font-size: 3.5vw; 
      }
      .main-text {
        font-size: 5vw; 
      }
    }
  }
}
</style>