<template>
  <div>
    <div class="header">
      <div class="logo-merck">
        <img src="./assets/images/common/logo_merck.png" alt="merck"> 
      </div>
      <div class="logo-sigmaaldrich">
        <img src="./assets/images/common/logo_sigma.png" alt="Sigma-Aldrich">  
      </div> 
    </div>
    <bulk-estimate-event></bulk-estimate-event> 
    <div class="merck-logo">
      <div class="logo-merck">
        <img src="./assets/images/common/logo_merck.png" alt="merck">
      </div> 
    </div> 

    <div id="footer">
			<div class="clearfix">
				<div class="sns-icon">
					<a href="https://www.youtube.com/channel/UC1q6842hLnsMG_0JdHs4KWw?utm_source=kr&amp;utm_medium=social&amp;utm_campaign=youtube" target="_blank">
						<img src="./assets/images/footer/icon_youtube.png" alt="유튜브">
					</a>
					<a href="https://pf.kakao.com/_xjxeLHT?utm_source=kr&amp;utm_medium=social&amp;utm_campaign=kakao-dealer" target="_blank">
						<img src="./assets/images/footer/icon_kakao.png" alt="카카오">
					</a>
				</div>
				<div class="copyright"> 
          <p>© 2021 Merck KGaA, Darmstadt, Germany and/or its affiliates. All Rights Reserved. Merck 라이프사이언스 비즈니스는 미국 및 캐나다에서 MilliporeSigma로 운영됩니다.<br/>Merck 및 the Vibrant M은 독일 담스타트 Merck KGaA 또는 그 자회사의 상표입니다. 다른 모든 상표는 해당 소유주의 자산입니다.<br/>머크 주식회사 <span></span> 대표자:김우규 <span></span> 서울시 강남구
          테헤란로508 해성2빌딩 3층 <span></span> 02-2185-1700 <span></span><a href="mailto:rnainsidesales@merckgroup.com"> rnainsidesales@merckgroup.com</a> <span></span> 사업자등록번호: 220-81-09507</p>
				</div>
			</div>
			<div class="footer-bottom">
				<ul>
					<li>
						<a href="/frt/etc/terms" target="_blank">사이트이용약관</a>
					</li>
					<li>
						<a href="https://www.sigmaaldrich.com/help-welcome/ordering-product/terms-and-conditions-of-sale/terms-and-conditions-korea.html">거래약관</a>
					</li>
					<li>
						<a href="http://www.ftc.go.kr/www/bizCommView.do?key=232&amp;apv_perm_no=2001322008330201120&amp;pageUnit=10&amp;searchCnd=bup_nm&amp;losearchKrwd=%EB%A8%B8%ED%81%AC&amp;pageIndex=1" target="_blank">
							통신판매번호:2007-강남-1120
						</a>
					</li>
					<li>
						<a h="" href="/frt/etc/privacy_policy" target="_blank"> 개인정보처리방침 </a>
					</li>
				</ul>
			</div>
		</div>  
  </div>
</template>

<script>
import BulkEstimateEvent from './components/BulkEstimateEvent';

export default {
  name: 'App',

  components: {
    BulkEstimateEvent,
  },

  data: () => ({
    //
  }),
};
</script>
<style lang="scss">
.header, 
.merck-logo { 
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1200px;
  height: 120px;
  margin: 0 auto;  
  text-align: center;   
}
.header { 
  justify-content: space-between;
  .logo-sigmaaldrich {
    img {
      display: block;
      height: 48px;
    }
  }
  .logo-merck {
    img {
      display: block;
      height: 28px; 
    } 
  }
} 
.merck-logo {
  justify-content: center;
  .logo-merck {
    img {
      display: block;
      height: 26px;
    }
  } 
} 
@media (max-width: 1200px) {
  .header, 
  .merck-logo {
    padding: 0 20px; 
  } 
}  
@media (max-width: 700px) {
  .header, 
  .merck-logo { 
    height: 20vw;
  }
  .header {
    .logo-sigmaaldrich {
      img { 
        height: 8vw;
      }
    }
  .logo-merck {
    img { 
      height: 4.5vw;
    } 
  }
  }
  .merck-logo { 
    .logo-merck {
      img { 
        height: 4.5vw;
      }
    }
  }
}
/* footer */
#footer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 33px 20px 45px;
  background: #424242;
  box-sizing: border-box;
}

#footer a {
  margin: 0;
  padding: 0;
  font-size: inherit;
  text-decoration: none;
  background: transparent;
  color: inherit;  
  font-family: 'Noto-VerdanaPro';
}

#footer .sns-icon {
  float: right;
  text-align: right;
}

#footer .sns-icon a:first-child {
  margin-right: 14px;
}

#footer .sns-icon a {
  display: inline-block; 
}

#footer .sns-icon a img {
  width: 38px;
}

#footer p span::before {
  content: "|";
  font-size: 12px;
  font-weight: normal;
}

#footer .copyright {
  width: 90%; 
  margin-bottom: 22px;
}

#footer .copyright p {
  font-size: 14px;
  line-height: 28px;
  font-family: 'Noto-VerdanaPro';
  letter-spacing: -0.5px;
  color: #dcdcdc;
  word-break: keep-all;
}

#footer .footer-bottom ul {
  list-style: none;
}
#footer .footer-bottom ul::after {
  content: "";
  display: block;
  clear: both;
}

#footer .footer-bottom ul li {
  float: left;
  margin-bottom: 10px;
  font-size: 16px;
  letter-spacing: -0.5px;
  font-weight: bold;
  color: #fff;
}

#footer .footer-bottom ul li a {
  padding: 0 10px;
  word-break: break-all;
  font-family: 'Noto-VerdanaPro';
}

#footer .footer-bottom ul li:first-child a {
  padding-left: 0;
}

#footer .footer-bottom ul li::after {
  content: "|";
  font-size: 20px;
  color: #fff;
  font-weight: 500;
}

#footer .footer-bottom ul li:last-child::after {
  content: "";
}

@media (max-width: 767px) {
  #footer {
    padding: 5.555vw 3.255208333vw 8.4375vw;
  }

  #footer .sns-icon {
    float: none;
    text-align: left;
  }

  #footer .sns-icon a:first-child {
    margin-right: 0.5rem;
  }

  #footer .sns-icon a img {
    max-width: 38px;
    width: 6.555vw;
  }

  #footer .copyright {
    width: 100%;
    margin-top: 1.8rem;
    margin-bottom: 1.8rem;
  }

  #footer .copyright p {
    font-size: 1rem;
    line-height: 1.7rem;
    word-break: keep-all;
  }

  #footer p span::before {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  #footer .footer-bottom ul li {
    font-size: 1rem;
    word-break: break-all;
  }

  #footer .footer-bottom ul li a {
    padding: 0 4px;
  }

  #footer .footer-bottom ul li::after {
    font-size: 1rem;
  }
}

</style>
